<template>
  <van-button :type="type" :disabled="disabled" :size="size" @click="exportData">{{
    content
  }}</van-button>
</template>

<script>
import XLSX from 'xlsx';
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  name: 'ExportButton',
  props: {
    content: {
      type: String,
      required: true,
      default: '导出',
    },
    size: {
      type: String,
      default: 'normal',
    },
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    headerData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'primary',
    },
    fileName: {
      type: String,
      default: '导出文件',
    },
  },
  data() {
    return {
      // 实时签名
      signatrue: '',
      timestamp: '',
      fileSize: '',
    };
  },
  created() {
    this.getSignatrue();
  },
  computed: {
        ...mapGetters(['getConfigItem']),
    },
  methods: {
    // 点击导出，通知外层更新数据
    exportData() {
      this.exportToXLSX();
    },

    sheet2blob(sheet, sheetName) {
      const newWorkbook = XLSX.utils.book_new();
      // 将工作表附加到工作簿
      XLSX.utils.book_append_sheet(newWorkbook, sheet, 'SheetJS');

      const wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'base64',
      };

      const excelBuffer = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      this.fileSize = blob.size;
      const wbout = XLSX.write(newWorkbook, wopts);
      console.log(wbout);

      return wbout;
    },

    getSignatrue() {
      let data = {
        Url: window.location.href,
        Noncestr: 'otob',
        Timestamp: Math.round(new Date() / 1000) + '',
      };
      // 获取触发接口时的时间戳
      this.timestamp = data.Timestamp * 1;
      console.log(this.timestamp);
      axios
        .post(this.getConfigItem('signature'), data, {
          headers: {
            authToken: sessionStorage.getItem('AuthToken'),
                userid: sessionStorage.getItem('userid'),
          },
        })
        .then((res) => {
          console.log(res, '当前Signatrue');
          this.signatrue = res.data.signature;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async openDownloadDialog(dataurl, name) {

      const toast1 = this.$toast.loading({
        message: '加载中...',
        duration: 0,
      });
      try {
        // const arr = dataurl.split(',');
        const bstr = atob(dataurl);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const newFile = new File([u8arr], name, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const UserId = sessionStorage.getItem('userid');
        const reqData = new FormData();
        reqData.append('file', newFile);
        // formData.append("file", item.file);
        const { data } = await axios.post(`/relay/cosfile/index`, reqData, {
          headers: {
            AuthToken: sessionStorage.getItem('AuthToken'),
                userid: sessionStorage.getItem('userid'),
            'Content-Type': 'multipart/form-data',
          },
        });
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 在测试没问题之后，关闭debug模式，防止弹窗
        appId: sessionStorage.getItem('appid') || this.getConfigItem('corpID'), // 必填，企业微信的corpID
          // timestamp: 1668153749, // 必填，生成签名的时间戳
          timestamp: this.timestamp, // 必填，生成签名的时间戳
          nonceStr: 'otob', // 必填，生成签名的随机串
          // signature: "4f8304da162dea2fa0a1fd876927ae3340b92562", // 必填，签名，见 附录-JS-SDK使用权限签名算法
          signature: this.signatrue, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ['invoke'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        console.log(this.fileSize)
        wx.ready((e) => {
          wx.invoke('previewFile', {
            url: data?.FileList?.[0]?.url, // 需要预览文件的地址(必填，可以使用相对路径)
            name, // 需要预览文件的文件名(不填的话取url的最后部分)
            size: this.fileSize, // 需要预览文件的字节大小(必填)
          });
        });
        toast1?.clear();
        // setTimeout(() => location.reload(), 500)
        // window.open(data?.FileList?.[0]?.url,'_self');
      } catch (err) {
        this.$message.error(err);
        console.log(err);
        toast1?.clear();
      }
    },

    // 导出excel
    exportToXLSX() {
      try {
        // 时间
        const exportData = this.tableData.map((item) => {
          const obj = {};
          Object.entries(this.headerData).forEach(([key, value]) => {
            obj[value] = item[key];
          });
          return obj;
        });
        console.log(exportData, 1122);
        const date = new Date();
        const dateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const sheet = XLSX.utils.json_to_sheet(exportData);

        this.openDownloadDialog(
          this.sheet2blob(sheet),
          `${this.fileName}_${dateTime}_export_data.xlsx`
        );
        // 工作表
        // 创建工作簿

        // // 写入数据
        // const res = XLSX.writeFile(newWorkbook, `${this.fileName}_${dateTime}_export_data.xlsx`);
        // XLSX.writeFileAsync(`${this.fileName}_${dateTime}_export_data.xlsx`, newWorkbook, (a, b) => console.log(a, b));
      } catch (err) {
        this.$message.error(err);
        console.log(err);
      }
    },
  },
};
</script>
<style scoped>
.button {
  position: sticky;
}
</style>
