<template>
  <div class="w">
    <div v-loading.fullscreen.lock="loading" class="flex-h-between flex-vc">
      <div class="flex">
        <!-- <div class="btn" @click="onSignup">
          <el-icon class="f18" :name="SIGNUP_TYPE[signType].icon" />
          <div class="f12">{{ signDesc }}</div>
        </div> -->
        <van-button
          class="btn"
          round
          color="linear-gradient(to right, #ff9034, #ee0a24)"
          @click="onSignup"
        >
          <el-icon class="mr5" :name="SIGNUP_TYPE[signType].icon" />
          <span>{{ signDesc }}</span>
        </van-button>
        <div v-if="id" class="desc ml5">
          <div class="f12 lh15">
            <span>{{ time }}</span>
          </div>
          <div class="f12 lh15">
            <span>{{ address }}</span>
          </div>
        </div>
      </div>
      <div class="flex-hc flex-vc" @click="onClear" v-if="clearble && id">
        <el-icon class="f16" name="error" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TMap from '@/api/MapApi';
import { addSignUp, deleteSignUp } from '@/api/signUp/signUpApi';
import dayjs from 'dayjs';
import { SIGNUP_TYPE } from './constants';
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      default: () => ({}),
      type: Object,
    },
    signType: {
      type: String,
      default: 'common',
    },
    signDesc: {
      type: String,
      default: '打卡',
    },
    clearble: {
      default: true,
      type: Boolean,
    },
    ticketId: {
      default: '1',
      type: String,
    },
    taskId: {
      default: '1',
      type: String,
    },
    username: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      signatrue: '',
      address: '',
      loading: false,
      timestamp: '',
      latitude: '',
      longitude: '',
      time: '',
      id: '',
      SIGNUP_TYPE,
    };
  },
  watch: {
    value(newVal) {
      if (newVal && newVal.Id) {
        this.address = newVal.Address;
        this.time = newVal.SignTime;
        this.id = newVal.Id;
      } else {
        this.id = '';
        this.address = '';
        this.time = '';
      }
    },
  },
  created() {
    // 获取signature签名  不管是否过期
    this.getSignatrue();
  },
  computed: {
        ...mapGetters(['getConfigItem']),
    },
  methods: {
    onSignup() {
      this.$dialog
        .confirm({
          title: (this.address ? '重新' : '') + (this.signDesc || '打卡'),
          message: '即将进行打卡操作，请确认是否继续',
        })
        .then(() => {
          this.loading = true;
          this.getLocation();
        })
        .catch(() => {});
    },

    onClear() {
      this.$dialog
        .confirm({
          title: `取消${this.signDesc || '打卡'}`,
          message: '即将取消打卡操作，请确认是否继续',
        })
        .then(() => {
          this.deleteSignUp();
        })
        .catch(() => {});
    },
    async deleteSignUp() {
      try {
        this.loading = true;
        const res = await deleteSignUp(this.username, this.id);
        if (!res.Affects) throw new Error();
        this.$emit('change', null);
        this.loading = false;
        this.latitude = '';
        this.latitude = '';
        this.time = '';
        this.address = '';
        this.id = '';
        this.$toast.success('打卡已取消');
      } catch (e) {
        console.log(e);
        this.$toast.fail('取消打卡失败');
      }
    },
    async getSignatrue() {
      const data = {
        Url: window.location.href,
        Noncestr: 'otob',
        Timestamp: `${Math.round(new Date() / 1000)}`,
      };
      // 获取触发接口时的时间戳
      this.timestamp = data.Timestamp * 1;
      console.log(this.timestamp);
      try {
        const res = await axios.post(this.getConfigItem('signature'), data, {
          headers: {
            authToken: sessionStorage.getItem('AuthToken'),
                userid: sessionStorage.getItem('userid'),
          },
        });
        this.signatrue = res.data.signature;
      } catch (e) {
        this.$toast.fail('初始化jssdk失败，请稍后再试');
      }
    },

    getLocation() {
      if (wx) {
        this.wxLocation();
      } else if (navigator.geolocation) {
        this.callGeo();
      } else {
        alert('获取地址失败，请确认打开定位服务后再试');
        this.loading = false;
      }
    },

    callGeo() {
      try {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            this.latitude = coords.latitude; // 纬度，浮点数，范围为90 ~ -90
            this.longitude = coords.longitude; // 经度，浮点数，范围为180 ~ -180。
            this.callTMap();
          },
          (e) => {
            alert('H5获取地址失败，请稍后再试: ' + JSON.stringify(e));
            this.loading = false;
          }
        );
      } catch (e) {
        alert('获取地址失败，请稍后再试: ' + JSON.stringify(e));
        this.loading = false;
      }
    },

    wxLocation() {
      try {
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 在测试没问题之后，关闭debug模式，防止弹窗
        appId: sessionStorage.getItem('appid') || this.getConfigItem('corpID'), // 必填，企业微信的corpID
          // timestamp: 1668153749, // 必填，生成签名的时间戳
          timestamp: this.timestamp, // 必填，生成签名的时间戳
          nonceStr: 'otob', // 必填，生成签名的随机串
          signature: this.signatrue, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ['getLocation'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        wx.ready((e) => {
          wx.getLocation({
            type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: (res) => {
              this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              this.callTMap();
            },
            cancel: (res) => {
              alert('打卡已取消');
              this.loading = false
            },
            fail: (res) => {
              alert('企微SDK定位失败，请打开定位权限: ' + JSON.stringify(res));
              this.callGeo();
            },
          });
        });
      } catch (e) {
        alert('定位失败，请打开定位权限: ' + JSON.stringify(e));
        this.callGeo();
      }
    },


    async callTMap() {
      const initMap = await TMap.init();
      if (!initMap) {
        alert(`腾讯地图SDK启动失败，请稍后重试: ${JSON.stringify(e)}`);
        this.loading = false;
        return;
      }
      try {
        const coder = new initMap.service.Geocoder();
        const latlng = new initMap.LatLng(this.latitude, this.longitude);
        const res = await coder.getAddress({ location: latlng });
        this.address =
          res.result &&
          res.result.formatted_addresses &&
          (res.result.formatted_addresses.recommend || res.result.formatted_addresses.rough);
        this.signUp();
      } catch (e) {
        this.$toast.fail(`腾讯地图SDK启动失败，请稍后重试: ${JSON.stringify(e)}`);
        this.loading = false;
      }
    },

    async signUp() {
      try {
        const params = {
          Address: this.address,
          Latitude: this.latitude,
          Longitude: this.longitude,
          SignUser: this.username,
          TaskId: this.taskId || '0',
          TicketId: this.ticketId || '0',
        };
        const res = await addSignUp(params);
        if (res && res.LastId) {
          this.time = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
          this.id = res.LastId;
          this.$emit('change', {
            ...params,
            SignDesc: this.signDesc,
            Id: this.id,
            SignTime: this.time,
          });
          this.$toast.success(`${this.signDesc}记录成功`);
        } else {
          this.$toast.fail('打卡失败，请稍后重试');
        }
      } catch (e) {
        console.log(e);
        this.$toast.fail('打卡失败，请稍后重试');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  height: 50px;
  flex: 1 0 auto;
}
.lh15 {
  line-height: 15px;
}

.desc {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-between;
}
</style>
