<!-- 网络资产出库计量表格 -->
<template>
  <div class="content">
    <div class="table">
      <el-table
        key="tableKey"
        ref="tableData"
        :data="pageData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        v-loading="metering_loading"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column
          prop="seqId"
          label="序号"
          width="75"
          :show-overflow-tooltip="true"
          align="center"
          fixed="left"
        >
        </el-table-column
        ><el-table-column
          prop="assets_type"
          label="资产类型"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column prop="mode" label="管理方式" :show-overflow-tooltip="true" align="center">
        </el-table-column
        ><el-table-column prop="type" label="类型" :show-overflow-tooltip="true" align="center">
        </el-table-column
        ><el-table-column
          prop="pn"
          label="型号/箱规格"
          :show-overflow-tooltip="true"
          align="center"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="enabled"
          label="是否可用"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="accessories_info"
          label="辅料信息"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="nowinLib"
          label="现部门"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column prop="inLib" label="逻辑库" :show-overflow-tooltip="true" align="center">
        </el-table-column
        ><el-table-column prop="dept_name" label="部门" :show-overflow-tooltip="true">
        </el-table-column
        ><el-table-column
          prop="outway"
          label="出库方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="amount"
          label="申请数量"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column
        ><el-table-column
          prop="isSelect"
          label="是否选择过资产位置"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template v-slot="{ row }">
            <el-tag v-if="row.isSelect" type="success">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="outbound_info" label="选择资产位信息" align="center">
          <template v-slot="{ row }">
            <el-button @click="showDrawer(row)" type="primary">编辑</el-button>
            <div class="location">
              <van-popup
                v-model="row.drawer"
                position="bottom"
                :close-on-click-overlay="false"
                closeable
                lock-scroll
                :z-index="99"
                close-icon-position="top-left"
                :style="{ height: '85%', width: '100%' }"
              >
                <van-nav-bar title="选择资产位置信息" />
                <div class="operatingBtn">
                  <div>
                    <h3>申请数量:{{ amounts || '---' }}</h3>
                  </div>
                  <van-button round type="info" size="small" @click="addRow(row)"
                    >新增资产位置信息</van-button
                  >
                </div>
                <van-form @submit="onSubmit">
                  <div v-for="item in formData.locationTable" :key="item.id">
                    <van-cell-group>
                      <van-field
                        v-model="item.position"
                        is-link
                        readonly
                        label="资产位置"
                        name="position"
                        placeholder="请选择资产位置"
                        @click="handleShowCascader(item.id, item)"
                        :rules="[{ required: true, message: '' }]"
                      />

                      <van-field
                        v-model="item.Actual_PN"
                        is-link
                        readonly
                        label="实际PN"
                        name="Actual_PN"
                        placeholder="请选择实际PN"
                        @click="handleShowPicker(item.id, item)"
                        :rules="[{ required: true, message: '' }]"
                      />

                      <van-field
                        readonly
                        v-model="item.quantityStock"
                        label="库存数量"
                        name="quantityStock"
                      />

                      <van-field
                        v-model="item.outbound_quantity"
                        name="outbound_quantity"
                        label="出库数量"
                        type="number"
                        placeholder="请输入出库数量"
                        @blur="onBlur"
                        :rules="[{ required: true, message: '' }]"
                      />
                    </van-cell-group>
                    <div>
                      <van-button type="danger" class="VantBtn" @click="handleDeteleRow(item.id)"
                        >删除</van-button
                      >
                    </div>
                  </div>

                  <van-popup v-model="showCascader" round position="bottom">
                    <van-cascader
                      v-model="cascaderValue"
                      get-container="body"
                      title="请选择资产位置"
                      :options="AssetLocationData"
                      @close="showCascader = false"
                      @finish="(val) => onFinish(val)"
                    />
                  </van-popup>

                  <van-popup v-model="showPicker" round position="bottom">
                    <van-picker
                      title="实际PN"
                      show-toolbar
                      :columns="PnList"
                      @confirm="(val) => ActualPnConfirm(val)"
                      @cancel="showPicker = false"
                    />
                  </van-popup>

                  <div style="margin: 16px">
                    <van-button round block type="info" native-type="submit">保存</van-button>
                  </div>
                </van-form>
              </van-popup>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        :current-page="currentPage"
        :page-sizes="pageSize"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getMachineRoom,
  getAssetLocation,
  saveProcess,
  getWirequantity,
} from '@/api/OutboundMeasurementTable/index.js';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    campus_name: {
      type: String,
      default: '',
    },
    metering_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // showPopup: false,
      pageData: [],
      tableData: [],
      PnList: [],
      currentPage: 1,
      pageSize: [20],
      direction: 'rtl',
      formData: {
        locationTable: [
          {
            id: 1,
            position: '',
            Actual_PN: '',
            outbound_quantity: '',
            quantityStock: '0',
          },
        ],
      },
      AssetLocationData: [],
      amounts: '',
      // 出库数量是否达标
      isArrive: false,
      // 当前行出库数量总和
      rowcCounts: 0,
      cascaderLoading: false,
      showPicker: false,
      PickerID: '',
      showCascader: false,
      CascaderID: '',
      position: '',
      cascaderValue: '',
      rowInfo: {},
      quantityStockNmus: 0,
      pn: '',
      locationNameList: [],
      locationNameToIdList: [],
      scope: '',
    };
  },

  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.tableData = [...val];
          this.changePage(this.currentPage);
        });
      },
      deep: true,
    },
    rowcCounts: {
      handler() {
        if (this.rowcCounts > this.amounts) {
          this.isArrive = true;
        } else if (this.rowcCounts < this.amounts) {
          this.isArrive = false;
        } else if ((this.rowcCounts = this.amounts)) {
          this.isArrive = true;
        }
      },
      deep: true,
    },
  },
  mounted() {},

  methods: {
    handleShowCascader(id, scope) {
      this.scope = scope;
      this.showCascader = true;
      this.CascaderID = id;
      this.cascaderValue = '';
    },

    handleShowPicker(id, scope) {
      this.scope = scope;
      this.showPicker = true;
      this.PickerID = id;
    },
    ActualPnConfirm(value) {
      const CascaderItem = this.formData.locationTable.filter((item) => item.id === this.PickerID);
      CascaderItem[0].Actual_PN = value;
      this.pn = value;
      // if (this.pn && this.locationNameList.length !== 0) this.getWirequantity(this.scope)
      if (this.scope.Actual_PN && this.scope.position) this.getWirequantity(this.scope);
      this.showPicker = false;
    },

    onFinish({ selectedOptions }) {
      const position = selectedOptions.map((option) => option.text).join(',');
      const CascaderItem = this.formData.locationTable.filter(
        (item) => item.id === this.CascaderID
      );
      CascaderItem[0].position = position;
      this.locationNameList = selectedOptions.map((option) => option.text);
      // if (this.pn && this.locationNameList.length !== 0) this.getWirequantity(this.scope)
      if (this.scope.Actual_PN && this.scope.position) this.getWirequantity(this.scope);
      this.showCascader = false;
    },
    // 根据PN获取出库数量
    async getWirequantity(scope) {
      try {
        const { code, data } = await getWirequantity({
          asset_type: this.rowInfo.assets_type,
          pn: this.pn,
          location_name_list: this.locationNameList,
          location_name_to_id_list: this.locationNameToIdList,
          enabled: this.rowInfo.enabled,
        });
        if (code === 0) {
          this.quantityStockNmus = Number(data.count || 0);
          let counts = 0;
          scope.outbound_quantity = this.quantityStockNmus;
          for (const item of this.formData.locationTable) {
            counts += Number(item.outbound_quantity || 0);
          }
          if (counts <= this.amounts) {
            scope.outbound_quantity = this.quantityStockNmus;
            scope.quantityStock = this.quantityStockNmus;
            if (counts != this.amounts) {
              if (this.quantityStockNmus === 0) {
                this.$message.warning(
                  `当前资产位置下的【PN:${this.pn}】的库存数量为【${this.quantityStockNmus}】不满足申请数量【${this.amounts}】,请再继续选择其它资产位置`
                );
              } else {
                this.$message.warning(
                  `当前资产位置下的【PN:${this.pn}】的库存数量为【${this.quantityStockNmus}】不满足申请数量【${this.amounts}】,请再新增资产位置`
                );
              }
            }
          } else {
            scope.outbound_quantity = this.amounts - (counts - this.quantityStockNmus);
            scope.quantityStock = this.quantityStockNmus;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 打开抽屉
    showDrawer(row) {
      try {
        row.drawer = true;
        this.rowInfo = row;
        this.amounts = row.amount;
        this.formData.locationTable = [...row.outbound_info] || [
          {
            id: 1,
            position: '',
            Actual_PN: '',
            quantityStock: '0',
            outbound_quantity: 1,
          },
        ];
        this.PnList = row.pn?.split('|||') || [];
        if (this.PnList.length === 1) {
          this.pn = this.PnList[0];
          this.formData.locationTable = this.formData.locationTable.map((item) => {
            return {
              ...item,
              Actual_PN: this.PnList[0],
            };
          });
        }
        this.PnList.length === 1 ? null : (this.pn = '');
        this.locationNameList = '';
        this.getMachineRoom();
        this.$emit('change', this.tableData);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取资产位置信息
    async getMachineRoom() {
      try {
        const { result } = await getMachineRoom(this.campus_name);
        const { code, result: data } = await getAssetLocation(result);
        if (code === 0) {
          this.AssetLocationData = data;
          this.locationNameToIdList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit(values) {
      this.rowInfo.outbound_info = this.formData.locationTable;
      let counts = 0;
      let inventory_data = 0;
      for (const [index, item] of this.formData.locationTable.entries()) {
        if (Number(item.outbound_quantity) > (Number(item.quantityStock) || 0)) {
          this.$message.warning(
            `第${index + 1}条数据的出库数量【${item.outbound_quantity}】大于库存数量【${
              item.quantityStock
            }】,请确认出库数量!`
          );
          return;
        }
        counts += Number(item.outbound_quantity || 0);
        inventory_data += Number(item.quantityStock || 0);
      }
      // 检验出库数量和申请数量是否一致
      if (counts != this.amounts) {
        this.$message({
          duration: 1500,
          message:
            `出库数量为【${counts}】` + `与申请数量【${this.amounts}】不一致,请确认出库数量!`,
          type: 'warning',
        });
        return;
      }
      if (inventory_data < this.amounts) {
        this.$message.warning(
          `出库总数量为【${counts}】大于库存总数量【${inventory_data}】,请确认出库数量!`
        );
        return;
      }
      // 计算多行出库数量
      let allCounts = 0;
      for (const items of this.tableData) {
        if (!Array.isArray(items.outbound_info)) continue;
        for (const item of items.outbound_info) {
          allCounts += Number(item.outbound_quantity || 0);
        }
      }
      // this.showPopup = false
      this.rowInfo.drawer = false;
      // 是否选择资产信息
      this.$set(this.rowInfo, 'isSelect', true);
      this.rowInfo.outbound_info = this.rowInfo.outbound_info.map((item) => {
        return {
          ...item,
          position: item.position.split(','),
        };
      });
      const parameters = {
        assets_data_metering: this.tableData,
        measurementQuantitys: allCounts,
      };
      const { code, msg } = await saveProcess(parameters, this.context.instanceId);
      if (code === 0) {
        this.$emit('changeOutboundQuantity', allCounts);
        this.$emit('change', this.tableData);
        this.$message({
          duration: 1500,
          message: '保存成功',
          type: 'success',
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 添加行
    addRow(row) {
      try {
        // 计算当出库数量总数
        let allCounts = 0;
        for (const item of this.formData.locationTable) {
          allCounts += Number(item.outbound_quantity || 0);
        }
        if (allCounts >= this.amounts) {
          this.$message({
            duration: 1500,
            message: '【出库数量】已达标,无需再新增!',
            type: 'warning',
          });
          return;
        }
        if (this.formData.locationTable.length > 7) {
          this.$message({
            duration: 1500,
            message: '已超过最大新增行数!',
            type: 'warning',
          });
          return;
        }
        this.PnList = row.pn.split('|||') || [];
        const randomId = this.generateRandomId(10);
        const newRow = {
          id: randomId,
          position: '',
          quantityStock: '0',
          Actual_PN: this.PnList.length === 1 ? this.PnList[0] : '',
          outbound_quantity: 1,
        };
        // 删除 资产位置和实际pn
        this.PnList.length === 1 ? null : (this.pn = '');
        this.locationNameList = [];
        this.formData.locationTable.push(newRow);
      } catch (error) {
        console.log(error);
      }
    },

    // 生成以随机id
    generateRandomId(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    onBlur() {
      // 计算单行出库数量
      let counts = 0;
      for (const item of this.formData.locationTable) {
        counts += Number(item.outbound_quantity || 0);
      }
      this.rowcCounts = counts;
      if (counts > this.amounts) {
        this.isArrive = true;
        this.$message({
          duration: 1500,
          message:
            `出库数量为【${counts}】` + `不能大于申请数量【${this.amounts}】，请确认一下出库数量`,
          type: 'warning',
        });
        return;
      }
    },

    // 删除当前行
    handleDeteleRow(id) {
      this.formData.locationTable = this.formData.locationTable.filter((item) => item.id !== id);
      let counts = 0;
      for (const item of this.formData.locationTable) {
        counts += Number(item.outbound_quantity || 0);
      }
      this.rowcCounts = counts;
    },
    changePage(page) {
      this.currentPage = page;
      const startIndex = (this.currentPage - 1) * this.pageSize[0];
      const endIndex = startIndex + this.pageSize[0];
      this.pageData = this.tableData.slice(startIndex, endIndex);
    },
  },
};
</script>

<style scoped lang="less">
.content {
  margin: 0;
  padding: 0;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .location {
    margin-top: 20px;

    .VantBtn {
      width: 100% !important;
    }

    .operatingBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .rowItem {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tipText {
        margin-right: 3px;
        font-size: 16px;
      }
    }

    .tips {
      display: flex;
      justify-content: start;
    }

    .title {
      font-size: 22px;
    }
  }
}
</style>
